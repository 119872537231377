import React from "react"
import PropTypes from "prop-types"

import { titleStyles } from "../../utils/styles"
import { colors } from "../../utils/presets"

const Title = ({ children, isInverted, customStyles, className }) => {
  let fontColor = false

  if (isInverted) {
    fontColor = colors.white
  }

  return (
    <h1
      css={[
        titleStyles,
        {
          color: fontColor,
        },
        customStyles,
      ]}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      className={className}
    />
  )
}

Title.propTypes = {
  isInverted: PropTypes.bool,
  color: PropTypes.string,
  customStyles: PropTypes.object,
}

export default Title
