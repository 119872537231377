import React from "react"
import propTypes from "prop-types"
import { keyframes } from "@emotion/core"

const rotate = keyframes({
  "0%": { transform: `rotate(0deg) scale(1)` },
  "10%": { transform: `rotate(8deg) scale(1.05)` },
  "20%": { transform: `rotate(16deg) scale(1.1)` },
  "30%": { transform: `rotate(24deg) scale(1.1)` },
  "40%": { transform: `rotate(16deg) scale(1.15)` },
  "50%": { transform: `rotate(24deg) scale(1.2)` },
  "60%": { transform: `rotate(32deg) scale(1.15)` },
  "70%": { transform: `rotate(24deg) scale(1)` },
  "80%": { transform: `rotate(16deg) scale(1.1)` },
  "90%": { transform: `rotate(8deg) scale(1.1)` },
  "100%": { transform: `rotate(0deg) scale(1)` },
})

const Metaball = ({ fill, opacity, right, className }) => (
  <div
    css={theme => [
      {
        position: `absolute`,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: -2,
        overflow: `visible`,
        color: theme.colors.purple[5],
        svg: {
          animation: `${rotate} 100s ${theme.transitions.curve.default} infinite`,
          animationDelay: `2s`,
          transformOrigin: `center`,
          willChange: `transform`,
          width: `calc(90vw + 25vh)`,
          height: `auto`,
          position: `absolute`,
          left: `-40vw`,
          [theme.mediaQueries.tablet]: {
            left: `-50vw`,
          },
        },
      },
      right && {
        right: `-50vw`,
        left: `auto`,
        top: `20vh`,
        [theme.mediaQueries.tablet]: {
          right: `-70vw`,
        },
      },
    ]}
    className={className}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1205 918">
      <path
        fill={fill || "currentColor"}
        fillRule="evenodd"
        d="M786.762 81.215c-5.731 0-11.435.12-17.11.348-106.873 4.14-114.975-2.673-172.065-38.455C555.367 15.836 505.072 0 451.077 0 325.84 0 220.502 85.158 189.724 200.742c-19.29 66.98-24.02 74.033-83.435 121.661l.01.005C41.498 374.42 0 454.288 0 543.865 0 700.6 127.012 827.66 283.69 827.66c26.16 0 51.486-3.559 75.539-10.19 81.736-20.874 101.626-24.156 209.346 39.146C632.095 895.55 706.808 918 786.762 918 1017.749 918 1205 730.68 1205 499.607c0-231.072-187.251-418.392-418.238-418.392"
        opacity={opacity || `1`}
      />
    </svg>
  </div>
)

Metaball.propTypes = {
  fill: propTypes.string,
  opacity: propTypes.string,
}

export default Metaball
