import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import ContentBlock from "../../components/content-block"
import Metaball from "../../components/metaball"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

import { subtitleStyles } from "../../utils/styles"
import { getLayout } from "../../utils/layout"
import { mediaQueries, colors } from "../../utils/presets"
import { getTheme } from "gatsby-interface"
import { rhythm } from "../../utils/typography"
import LazySVGImg from "../../components/lazy-svg"

const { space } = getTheme()

const styles = {
  ourStoryContainer: {
    maxWidth: rhythm(24),
    marginTop: space[9],
    h3: {
      ...subtitleStyles,
      fontWeight: 800,
      color: colors.gatsby,
    },
    [mediaQueries.desktop]: {
      marginBottom: space[9],
    },
  },
  profilesContainer: {
    paddingBottom: `12rem`,
  },
  skatingButler: {
    height: 200,
    width: 200,
    position: `absolute`,
    bottom: 0,
    left: `50%`,
    transform: `translateX(-50%)`,
  },
}

class AboutUsPage extends React.Component {
  render() {
    const { contentfulPage } = this.props.data

    const { title, subtitle, contentBlocks } = contentfulPage
    const layout = getLayout(this.props.location.pathname)

    return (
      <Layout
        pathname={this.props.location.pathname}
        background={layout.background}
      >
        <SEO contentfulPage={contentfulPage} url={this.props.location.href} />
        <Header isInverted={layout.inverted} />
        <main>
          <SkipNavTarget />
          <Container>
            <Metaball fill={colors.white} />
            <HeroContainer>
              <Title customStyles={{ color: colors.gatsby, marginBottom: 0 }}>
                {title}
              </Title>
              {subtitle && (
                <Subtitle customStyles={{ color: colors.gatsby }}>
                  {subtitle.subtitle}
                </Subtitle>
              )}
            </HeroContainer>
          </Container>
          {contentBlocks &&
            contentBlocks.map((cb, i) => (
              <Container
                customStyles={{
                  paddingBottom: space[9],
                  position: `relative`,
                  overflow: `hidden`,
                  ...(cb.__typename === `ContentfulProfilesBlock`
                    ? { ...styles.profilesContainer }
                    : false),
                }}
                innerCustomStyles={{
                  ...(cb.__typename === `ContentfulLongFormBlock`
                    ? { ...styles.ourStoryContainer }
                    : false),
                }}
                key={i}
              >
                {cb.__typename === `ContentfulProfilesBlock` && (
                  <LazySVGImg
                    src="about-skating-butler.svg"
                    css={styles.skatingButler}
                  />
                )}
                <div css={{ maxWidth: `none` }}>
                  <ContentBlock contentBlock={cb} />
                </div>
              </Container>
            ))}
        </main>
        <Footer />
      </Layout>
    )
  }
}

AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default AboutUsPage

export const pageQuery = graphql`
  query ContentfulAboutPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...ContentBlocks
      ...SocialMediaImage
      title
      subtitle {
        subtitle
      }
    }
  }
`
