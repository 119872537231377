import { colors } from "./presets"

export const getLayout = pageName =>
  (() => {
    switch (pageName) {
      case `/`:
        return {
          inverted: true,
          heroLayout: `Home`,
        }
      case `/about/`:
        return {
          heroLayout: `Duotone`,
          background: colors.purple[5],
        }
      case `/support/`:
        return {
          inverted: true,
          heroLayout: `Duotone`,
        }
      case `/why-gatsby/`:
        return {
          inverted: true,
          footerInverted: true,
          background: colors.night,
        }
      case `/use-cases/`:
        return {
          inverted: true,
          footerInverted: true,
          background: colors.night,
        }
      case `/guides/react/`:
        return {
          inverted: true,
        }
      default:
        return {
          inverted: false,
          heroLayout: `Default`,
        }
    }
  })()
